$headerTextColor: #cbcfcb;
$headerFontFamily: "Arvo", serif;
$headerFontSize: 2rem;

.header-big-image {
	height: 499px;
	width: 100vw;
	position: relative;
	background-size: cover;
	background-position: 50% 50%;
}

.header-overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background: rgba(0,0,0, 0.45);
	font-family: $headerFontFamily;
	font-size: $headerFontSize;
	color: $headerTextColor;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}