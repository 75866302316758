$secondFooterFont: "Open Serif", serif;
$secondFooterFontH2: "Arvo", serif;

.footer-big-image {
	height: 399px;
	width: 100vw;
	position: relative;
	background-image: url('/assets/img/catskills_view_from_soyuzivka.jpg');
	background-size: cover;
	background-position: 50% 50%;
}

.footer-overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background: rgba(0,0,0, 0.05);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.second-footer {
	background-color: rgba(245,245,245,1);
	border-top-color: rgba(240,240,240,1);
	padding: 30px 0 60px;
	box-sizing: border-box;
	font-family: $secondFooterFont;
	h2 {
		font-family: $secondFooterFontH2;
	}

	a {
		background-color: transparent;
		cursor: pointer;
		text-decoration: none;
		color: rgba(0,0,0,.8);
	}
	li {
		list-style: none;
	}
}

.footer-gmap {
	overflow: hidden;
	padding-bottom: 50%;
	position: relative;
	height: 0;

	iframe {
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		position: absolute;
	}
}
.footer-grid {
	margin: 0 auto;
	padding-right: 20px;
	padding-left: 30px;
	max-width: 1240px;
}

.footer-row {
	display: flex;
}

.footer-column {
	flex: 50%;
	padding: 10px;
	text-align: left;
}