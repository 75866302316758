$mainNavBackgroundColor: #0a0a0a;
$mainNavCurrentColor: #232323;
$mainNavTextColor: #cbcfcb;
$mainNavFont: "Open Sans", sans-serif;

nav.main-nav {
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		overflow: hidden;
		background-color: $mainNavBackgroundColor;
	}

	li {
		float: left;

		a {
			color: $mainNavTextColor;
			display: block;
			font-family: $mainNavFont;
			padding: 14px 16px;
			text-align: center;
			text-decoration: none;
			&:hover {
				background-color: $mainNavCurrentColor;
			}
		}

	}

	.active {
		background-color: $mainNavCurrentColor;
	}
}