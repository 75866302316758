$ourdogboxColor: #141414;
$ourdogboxBackgroundColor: #ffffff;
$ourdogboxFontFamily: 'Open-Sans', sans-serif;
$ourdogboxBackgroundColorFigcaptionA: #cccccc;
$ourdogboxColorFigcaption: #000000;
$ourdogboxFontFamilyH2: 'Arvo', serif;

.ourdogGrid {
	padding: 2px;

	& > ul {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
		grid-gap: 1rem;
		list-style-type: none;

	}
}


.ourdogbox {
	font-family: $ourdogboxFontFamily;
	position: relative;
	overflow: hidden;
	margin: 10px;
	min-width: 230px;
	max-width: 315px;
	width: 100%;
	color: $ourdogboxColor;
	text-align: left;
	line-height: 1.4em;
	font-size: 16px;
	background-color: $ourdogboxBackgroundColor;

	* {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-transition: all 0.25s ease;
		transition: all 0.25s ease;
	}

	img {
		max-width: 100%;
		vertical-align: top;
	}

	figcaption {
		width: 100%;
		background-color: $ourdogboxBackgroundColor;
		padding: 10px 25px 55px;
		position: relative;

		&:before {
			position: absolute;
			content: '';
			z-index: 2;
			bottom: 100%;
			left: 0;
			width: 100%;
			height: 80px;
			background-image: -webkit-liner-gradient(top, transparent 0%, $ourdogboxBackgroundColor 100%);
			background-image: linear-gradient(to bottom, transparent 0%, $ourdogboxBackgroundColor 100%);
		}

		a {
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 10px;
			background-color: $ourdogboxBackgroundColorFigcaptionA;
			color: $ourdogboxColorFigcaption;
			font-size: 0.8em;
			text-transform: uppercase;
			opacity: 0.65;
			width: 100%;
			text-align: center;
			text-decoration: none;
			letter-spacing: 1px;

			&:hover {
				opacity: 1;
			}
		}
	}

	h2 {
		margin: 0 0 10px;
		font-weight: 700;
		font-size: 1rem;
		line-height: 1.2em;
		font-family: $ourdogboxFontFamilyH2;
		text-transform: uppercase;
	}

	p {
		margin: 0 0 10px;
		font-size: 0.8rem;
		font-family: $ourdogboxFontFamily;
		letter-spacing: 1px;
		opacity: 0.9;
	}
}

