$backgroundColor: #fcfcfc;
$bodyColor: #000000;
$bodyFont: "Open Sans", sans-serif;

$contentColor: #3e3e3e;
$contentFontSize: 19px;
$contentFontWeight: 300;
$contentLineHeight: 31.35px;
$contentPadding: 16%;
$contentPaddingTop: 0;
$contentPaddingBottom: 0;

$contentMarginTopP: 37px;
$contentMarginBottomP: 37px;
$contentPaddingP: 6%;

$wideContentPadding: 6%;

body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $bodyFont;
  margin: 0;
  text-align: center;
}

.content {
	color: $contentColor;
	font-size: $contentFontSize;
	font-weight: $contentFontWeight;
	line-height: $contentLineHeight;
	padding-left: $contentPadding;
	padding-right: $contentPadding;
	padding-top: $contentPaddingTop;
	padding-bottom: $contentPaddingBottom;
	text-align: justify;

	h1 {
		text-align: center;
	}

	p {
		margin-top: $contentMarginTopP;
		margin-bottom: $contentMarginBottomP;
		padding-left: $contentPaddingP;
		padding-right: $contentPaddingP;
	}
}

.wide-content {
	color: $contentColor;
	font-size: $contentFontSize;
	font-weight: $contentFontWeight;
	line-height: $contentLineHeight;
	padding-left: $wideContentPadding;
	padding-right: $wideContentPadding;
	padding-top: $contentPaddingTop;
	padding-bottom: $contentPaddingBottom;
	text-align: justify;

	h1 {
		text-align: center;
	}

	p {
		margin-top: $contentMarginTopP;
		margin-bottom: $contentMarginBottomP;
		padding-left: $contentPaddingP;
		padding-right: $contentPaddingP;
	}
}